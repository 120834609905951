import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UtilService } from '../core/services/util.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  assetPath: string = '';
  content: string = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private util: UtilService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params) {
        this.content = params?.['content'];
      }
    });
  }

  ngOnInit(): void {
    this.assetPath = this.util.host;
  }
}
