<div class="modal-header px-0 py-0 border-0">
  <h1 class="modal-title fs-20 fw600 text-bodyGrey mb-0" id="callbackModalLabel">
    Schedule a call
  </h1>
</div>
<div class="modal-body mb-4">
  <div class="fs-16 fw400 text-bodyGrey mb-4">
    Consider your own availability and time zone, as well as any specific
    office hours or availability mentioned by the company. Select a date
    and time that works for you and, if possible, provide alternative
    options in case the initial choice is not available.
  </div>

  <form>
    <div class="row">
      <div class="col-12">
        <div class="mb-lg-4 mb-3">
          <label class="form-label fs-16 fw400 text-bodyGrey mb-1">Name</label>
          <input type="text" class="form-control inputTyp1" />
          <!-- <span class="text-danger fs-14" *ngIf="profileForm.get('firstname')?.errors?.['pattern']">
                    Please enter a valid name.
                  </span> -->
        </div>
      </div>

      <div class="col-12">
        <div class="mb-lg-4 mb-3">
          <label class="form-label fs-16 fw400 text-bodyGrey mb-1">Phone number</label>
          <input type="text" class="form-control inputTyp1" id="fName" />
          <!-- <span class="text-danger fs-14" *ngIf="profileForm.get('phone')?.errors?.['pattern']">
                    Please enter a valid phone number.
                  </span> -->
        </div>
      </div>
      <div class="col-12">
        <div class="mb-lg-4 mb-3">
          <div class="">
            <label class="form-label fs-16 fw400 text-bodyGrey mb-1">Date</label>
            <div>
              <input type="date" class="form-control inputTyp1" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-lg-4 mb-3">
          <div class="">
            <label class="form-label fs-16 fw400 text-bodyGrey mb-1">Best time to call</label>
            <div>
              <input type="time" class="form-control inputTyp1" step="600" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer p-0 border-0 d-block d-lg-flex justify-content-lg-center">
  <div class="d-grid d-lg-flex justify-content-lg-center gap-3">
    <button class="GA-canceCallback px-5 btn-secondary outline" (click)="close()">
      Cancel
    </button>
    <button class="GA-callbackSave px-5 btn-primary order-first order-md-last">Schedule</button>
  </div> 
</div>