import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { v4 as uuidv4 } from 'uuid';
import * as CryptoJS from 'crypto-js';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(
    private $http: HttpClient
  ) { }

  // Convert into Base64Url
  convertIntoBase64Url(source: any): string {
    let encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
    return encodedSource;
  }

  // Get authenticate
  getAuthenticate(head: any, data: any): string {
    const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(head));
    const encodedHeader = this.convertIntoBase64Url(stringifiedHeader);

    const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    const encodedData = this.convertIntoBase64Url(stringifiedData);

    const $t = encodedHeader + "." + encodedData;

    const $sign = CryptoJS.HmacSHA256($t, 'const');
    const $encodedSign = this.convertIntoBase64Url($sign);

    const $st = $t + "." + $encodedSign;

    return $st;
  }

  // Initiate authentication Service
  initiateAuthentication(wrapKeyId: string): any {
    let $uId = uuidv4();
    const $head = {
      alg: 'HS256',
      typ: 'JWT'
    };
    const $data = {
      token_use: 'id',
      jti: String($uId),
      keyId: wrapKeyId
    };
    const $t = this.getAuthenticate($head, $data);
    $uId = $uId.concat('function()');

    const $cryptEnc = CryptoJS.SHA256($uId);
    $uId = CryptoJS.enc.Hex.stringify($cryptEnc);

    return {
      uuidVal: $uId,
      identify: $t
    };
  }

  // Authenticate with server
  authenticateWithServer(val: string): Observable<any> {
    return this.$http.post(environment.domesticAPIUrl + 'oauth/authorize-co', { idToken: val});
  }
}
