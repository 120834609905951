import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './components/login/login.component';
import { ScheduleCallComponent } from './components/schedule-call/schedule-call.component';
import { ConfirmModalComponent } from './dialogue/confirm-modal/confirm-modal.component';
import { EditCompanyComponent } from './components/edit-company/edit-company.component';
import { NgSelectModule } from '@ng-select/ng-select'

@NgModule({
  declarations: [
    LoginComponent,
    ScheduleCallComponent,
    ConfirmModalComponent,
    EditCompanyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    LoginComponent,
    ScheduleCallComponent,
    ConfirmModalComponent,
    EditCompanyComponent,
    NgSelectModule
  ]
})
export class SharedModule { }
