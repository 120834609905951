import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestAPIsService } from './rest-apis.service';
import Constants from '../constants/constants';
import { ConfirmModalComponent } from 'src/app/shared/dialogue/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { EditCompanyComponent } from 'src/app/shared/components/edit-company/edit-company.component';

type ConfirmModalType = "email" | "LOA"

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private sessionService: SessionService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private restAPI: RestAPIsService,
    private router: Router) { }

  /**
   * Open confirmation modal for email verification and LOA consent
   * @param {string} modalType
   * @param {boolean} redirectToDashboard (Optional) Redirect to dashboard URL after completion. Default `false` 
   */
  openMailOrLoaConfirmation(modalType: ConfirmModalType, redirectToDashboard: boolean = false) {
    const email = this.sessionService.getItemFromSession(Constants.sessionConstants.EMAIL);
    const userDetails = this.sessionService.getItemFromSession(Constants.sessionConstants.USERDETAILS);
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    const modalRef: NgbModalRef = this.modalService.open(ConfirmModalComponent, {
      beforeDismiss: () => {
        this.sessionService.removeItemFromSession(Constants.sessionConstants.SHOWLOAPOPUP)
        return Promise.resolve(true)
      }
    });
    if (modalType == 'email') {
      modalRef.componentInstance.modalDataConfig = {
        header: "Email verification",
        body: `Authorize access to your energy data by e-signing the Letter of Authority and continue your journey.`,
        enableInput: true,
        actionBtnTitle: 'Resend link',
        title: "Authorize",
        closeButtonTitle: "Close",
        inputValue: email
      }
    }
    else {
      if (userDetails?.loaStatus == 'AWAITING') {
        modalRef.componentInstance.modalDataConfig = {
          title: "Authorize",
          header: "LOA Authentication",
          actionBtnTitle: 'Resend LOA',
          closeButtonTitle: "Close",
          body: `It looks like you missed the email containing your Letter of Authority (LOA) document. No worries! We have resent the LOA link to your email. Please check your inbox at <b>${email}</b> to review and sign the document.`
        }
      }
      else {
        modalRef.componentInstance.modalDataConfig = {
          title: "Authorize",
          header: "LOA Authentication",
          actionBtnTitle: 'Send LOA',
          closeButtonTitle: "Close",
          body: `To generate recommendations, we need access to your energy details. Please click "Send LOA" to create the necessary Letter of Authority (LOA) <small class='mt-3 d-block fs-16'>You will receive an mail from <b>no-reply@oneflow.com</b></small>`
        }
      }

    }
    modalRef.componentInstance.onActionClick.subscribe((inputValue: any) => {
      if (inputValue) {
        if (modalType == 'email')
          this.resendEmailVerification(inputValue, modalRef);
        else{
          this.getLoaConsent(modalRef, redirectToDashboard, this.isLOAAwaiting);
        }
      }
    })
  }

  /**
   * Resend email verification link to user and update email in sessionStorage if it is changed
   * @param {string} email
   * @param {NgbModalRef} modalRef
   */
  resendEmailVerification(email: string, modalRef: NgbModalRef): void {
    modalRef.componentInstance.successMessage = ''
    modalRef.componentInstance.errorMessage = ''
    this.spinner.show();
    const url = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.origin || window.location.origin;
    const source = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.source;
    const userDetails = this.sessionService.getItemFromSession(Constants.sessionConstants.USERDETAILS);
    if (userDetails.email != email) {
      userDetails['email'] = email;
    }
    const userPayload = {
      firstname: userDetails.firstname,
      phone: userDetails.phone,
      email: userDetails.email,
      companyName: userDetails.companyName,
      companyAddress: userDetails.companyAddress,
    }
    this.restAPI.postAPI('carbon/v1/add-user-data', userPayload, 'domestic').subscribe({
      next: (res) => {
        if (res.code === 400 && res.status === 'Error') {
          modalRef.componentInstance.errorMessage = res.data;
          this.spinner.hide()
        } else {

          this.sessionService.setItemIntoSession(
            Constants.sessionConstants.EMAIL,
            userDetails.email
          );
          this.sessionService.setItemIntoSession(
            Constants.sessionConstants.USERDETAILS,
            userDetails
          );
          const payload = {
            email: userDetails.email,
            subject: (source === 'localhost') ? 'NatWest Low Carbon Recommendation Login' : ((source === 'natwest') ? 'NatWest' : source?.toUpperCase()) + ' Low Carbon Recommendation Login',
            URL: url + '/login',
          };
          // this.subscriptions.push(
          this.restAPI.postAPI('carbon/v1/user-login', payload, 'domestic').subscribe({
            next: (res) => {
              modalRef.componentInstance.successMessage = res.message

              setTimeout(() => {
                this.modalService.dismissAll()
              }, 3000);
              this.spinner.hide();
            },
            error: (err: any) => {
              if (!!err?.error)
                modalRef.componentInstance.errorMessage = err.message
              this.spinner.hide();
            },
          })
          // );
        }

      },
      error: (err) => {
        this.spinner.hide();
        modalRef.componentInstance.errorMessage =
          err?.error?.error[0]?.message ||
          'Something went wrong. Please try again later.';
      },
    })

  }

  /**
   * Send LOA consent email to user and fetch user details from server and update loaStatus in sessionStorage
   * @param {NgbModalRef} modalRef Use this variable when it's called from any opened modal
   * @param {boolean} redirectToDashboard (Optional) Redirect to dashboard URL after completion. Default `false` 
   * @param {boolean} isResendMail (Optional) Used to re-send LOA mail. Default `false` 
   */
  getLoaConsent(modalRef: NgbModalRef | null, redirectToDashboard: boolean = false, isResendMail = false): void {
    if (modalRef) {
      modalRef.componentInstance.successMessage = ''
      modalRef.componentInstance.errorMessage = ''
    }
    this.spinner.show();
    const email = this.sessionService.getItemFromSession(Constants.sessionConstants.EMAIL);
    const userDetail = this.sessionService.getItemFromSession(Constants.sessionConstants.USERDETAILS);

    const path = isResendMail ? `carbon/v1/loa/${userDetail?.loaId}/resend` : 'carbon/v1/loa';

    this.restAPI
      .postAPI(path, { email }, 'domestic')
      .subscribe({
        next: (res) => {
          if (res.code == 200) {
            this.getUserDetails(email);
            if (modalRef)
            modalRef.componentInstance.successMessage = res.data.message;
            else{
              this.showMessage('Success', `We have sent the LOA link to your email. Please check your inbox at ${email} to review and sign the document`);
            }
          }
          else {
            if (modalRef)
              modalRef.componentInstance.errorMessage = res.message
            else
              this.showMessage('Error', res.message)
          }
          setTimeout(() => {
            if (modalRef)
              modalRef.close()
            if (redirectToDashboard)
              this.router.navigate(['/modules/dashboard'])
          }, 3000);
          this.spinner.hide();
        },
        error: (err) => {
          if (modalRef)
            modalRef.componentInstance.errorMessage = err.error.data
          else
            this.showMessage('Error', err.error.data)
          this.spinner.hide();
        },
      })
  }

  /**
   * Show confirm popup box for info, success, error and warning
   * @param {string} header 
   * @param {string} body 
   */
  showMessage(header: string, body: string) : void{
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.modalDataConfig = {
      header,
      body,
    }
  }

  /**
   * Fetch user details from server and add/update in session storage
   * @param {string} email
   */
  getUserDetails(email: string): void {
    const payload = {
      email
    };

    this.restAPI
      .postAPI(
        'carbon/v1/user-details?forceRefresh=true',
        payload,
        'domestic'
      )
      .subscribe({
        next: (data) => {
          // Store into session
          this.sessionService.setItemIntoSession(
            Constants.sessionConstants.USERDETAILS,
            data.data[0]
          );
        },
        error: (err) => console.log('ERROR: ', err),
      })
  }

  /**
   * Check user email verification status
   * @returns {boolean}
   */
  checkEmailVerificationStatus(): boolean {

    const isVerifyEmail = this.sessionService.getItemFromSession(
      Constants.sessionConstants.ISVERIFYEMAIL
    );
    const userDetails = this.sessionService.getItemFromSession(
      Constants.sessionConstants.USERDETAILS
    );

    return !isVerifyEmail && userDetails.firstname !== 'Guest'
  }

  /**
   * Check LOA is not signed
   * @returns {boolean}
   */
  checkLOAStatus(): boolean {

    const userDetails = this.sessionService.getItemFromSession(
      Constants.sessionConstants.USERDETAILS
    );
    const isVerifyEmail = this.sessionService.getItemFromSession(
      Constants.sessionConstants.ISVERIFYEMAIL
    );
    return isVerifyEmail && userDetails?.loaStatus != 'SIGNED';
  }

  /**
   * Check LOA status is SIGNED
   * @returns {boolean}
   */
  get isLOASigned(): boolean {
    const userDetails = this.sessionService.getItemFromSession(
      Constants.sessionConstants.USERDETAILS
    );
    return userDetails?.loaStatus == 'SIGNED';
  }

  /**
   * Check LOA is available
   * @returns {boolean}
   */
  get isLOAExist(): boolean {
    const userDetails = this.sessionService.getItemFromSession(
      Constants.sessionConstants.USERDETAILS
    );
    return !!userDetails?.loaId;
  }

  /**
   * Check LOA status is AWAITING
   * @returns {boolean}
   */
  get isLOAAwaiting(): boolean {
    const userDetails = this.sessionService.getItemFromSession(
      Constants.sessionConstants.USERDETAILS
    );
    return userDetails?.loaStatus == 'AWAITING';
  }

  /**
   * Check if meter type if half hourly for the selected site
   * @returns {boolean}
   */
  get isSelectedSiteMeterHH(): boolean {
    const userDetails = this.sessionService.getItemFromSession(
      Constants.sessionConstants.USERDETAILS
    );
    const selectedSite = this.sessionService.getItemFromSession(
      Constants.sessionConstants.SELECTEDSITE
    );
    const meter = userDetails.sites?.find((el: any) => el.id == selectedSite);

    return meter?.profileClass == '00' || meter?.meterType?.toLowerCase() == "h";
  }

  /**
   * Check if email exist
   * @returns {boolean}
   */
  get isEmailExist(): boolean {
    const email = this.sessionService.getItemFromSession(
      Constants.sessionConstants.EMAIL
    );
    return email ? true : false;
  }

  /**
   * Check email verification if it is not Guest
   * @returns {boolean}
   */
  get isEmailVerified(): boolean {

    const isVerifyEmail = this.sessionService.getItemFromSession(
      Constants.sessionConstants.ISVERIFYEMAIL
    );
    const userDetails = this.sessionService.getItemFromSession(
      Constants.sessionConstants.USERDETAILS
    );

    return isVerifyEmail && userDetails.firstname !== 'Guest';
  }

  /**
   * Check email verification and LOA status if meter type is half hourly for the selected site
   * @returns {boolean}
   */
  get isEmailAndLoaValid(): boolean {

    if (this.isEmailVerified) {
      if (this.isSelectedSiteMeterHH) {
        if (this.getUserCompany) {
          if (this.isLOASigned) {
            return true;
          }
          else {
            this.openMailOrLoaConfirmation('LOA');
            return false;
          }
        }
        else {
          const modalRef = this.modalService.open(EditCompanyComponent);
          modalRef.componentInstance.modalRef = modalRef;
          return false;
        }
      }
      else {
        return true;
      }
    }
    else {
      this.openMailOrLoaConfirmation('email');
      return false;
    }
  }

  /**
   * Return company name if exist
   * @returns {string | null}
   */
  get getUserCompany(): string | null {
    const user = this.sessionService.getItemFromSession(Constants.sessionConstants.USERDETAILS);
    if (user && user?.companyName) {
      return user.companyName;
    }
    return null;
  }

  get showLOAPopup(): boolean {
    return this.sessionService.getItemFromSession(Constants.sessionConstants.SHOWLOAPOPUP) ? true : false;
  }
}
