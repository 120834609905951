// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  product: 'perse',
  subject: 'Perse Low Carbon Recommendation Login',
  assets: 'https://assets.perse.io/',
  tncUrl: 'https://energyhelp.perse.io/termsandconditions',
  domesticAPIUrl: 'https://dev.api.thelabrador.co.uk/',
  businessAPIUrl: 'https://businessdev.api.thelabrador.co.uk/energy/v1/',
  prodAPIUrl: 'https://api.thelabrador.co.uk/',
  dfsUrl: 'https://exploration-071f83.webflow.io',
  solarPanalURL: 'https://solar.googleapis.com/v1/',
  resource_locator: 'U2FsdGVkX192qixrCGGatPyyOZ5JgJlfXxYWqyouY86AVVRAkktVgOiAwm93hdkGaX/DbFqO5qeqcE9+ael15g==',
  resource_identifier: 'U2FsdGVkX1+s5ejdvT1hgiPsye4vgpGwW16KWdK2pH4=',
  enc_version: 'U2FsdGVkX1822QSYaw7ipQadMskEG6emEEQeemR/7XU='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
