<main class="h-100">
  <header class="bg-championPurple">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h1 class="GA-logo landingLogo logo mb-0">
          <img height="70px" [src]="assetPath + 'logo-white.svg'" alt="logo" title="logo" />
        </h1>
        <div class="btn-group">
          <button class="GA-login btn-secondary d-flex justify-content-center" (click)="openLogin()">
            Login
          </button>
        </div>
      </div>

    </div>
  </header>
  <section class="banner bg-championPurple">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-7">
          <div class="ps-lg-2">
            <h1 class="fs-40 fw700 text-white mb-4">
              <span class="">
                Save Energy, <br class="d-none d-lg-block" />Carbon, &
                Money</span>
            </h1>
            <p class="fs-16 text-white mb-4 pb-2">
              <span class="text-white">
                Unleash your business's green potential with tailored decarbonisation recommendations, unlocking
                potential savings with a free account.
              </span>
            </p>

            <div class="d-grid d-md-flex mb-4">
              <button class="GA-login btn-primary-alt" (click)="openLogin()">Login</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 text-md-end">
          <img class="d-block img-fluid w-100" [src]="assetPath + 'hero02.png'" alt="">
        </div>
      </div>
    </div>
  </section>
  <section class="py-5 services">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="card-nat mb-4 mb-lg-0">
            <div class="card-header mh-180 pb-0">
              <h2 class="fs-22 fw700 text-championPurple mb-4">
                Select your site details
              </h2>
              <p class="fs-18 text-bodyGrey">
                We identify energy meters for each site and map the energy
                performance certificate.
              </p>
            </div>
            <div class="card-media pt-0">
              <img class="img-fluid" [src]="assetPath + 'feature01.png'" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-nat mb-4 mb-lg-0">
            <div class="card-header mh-180 pb-0">
              <h2 class="fs-22 fw700 text-championPurple mb-4">
                Review energy dashboard
              </h2>
              <p class="fs-18 text-bodyGrey">
                Review your energy, carbon, and cost profile along with potential savings.
              </p>
            </div>
            <div class="card-media pt-0">
              <img class="img-fluid" [src]="assetPath + 'feature02.png'" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-nat">
            <div class="card-header mh-180 pb-0">
              <h2 class="fs-22 fw700 text-championPurple mb-4">
                Tailored recommendations
              </h2>
              <p class="fs-18 text-bodyGrey">
                Review low carbon recommendations, return on investment, payback periods and
                start your net-zero transition.
              </p>
            </div>
            <div class="card-media pt-0">
              <img class="img-fluid" [src]="assetPath + 'feature03.png'" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pb-5">
    <div class="container">
      <div class="card-nat">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-lg-6 mb-4 mb-lg-0">
              <h3 class="fs-40 fw-700 text-championPurple mb-4 pb-2">
                Free recommendations
              </h3>
              <p class="fs-18 text-bodyGrey mb-4">
                Sign up, register your interest, and download your recommendations.
              </p>
              <div class="d-grid d-md-flex">
                <button class="GA-login btn-primary" (click)="openLogin()">Login</button>
              </div>
            </div>
            <div class="col-lg-6">
              <!-- <img class="img-fluid" [src]="assetPath + '1.png'" alt="" /> -->
              <div class="mb-4 mb-md-0">
                <div id="carouselRecommendation" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselRecommendation" data-bs-slide-to="0" class="active"
                      aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselRecommendation" data-bs-slide-to="1"
                      aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselRecommendation" data-bs-slide-to="2"
                      aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselRecommendation" data-bs-slide-to="3"
                      aria-label="Slide 4"></button>
                  </div>

                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img [src]="assetPath + 'recommendation/1.png'" class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img [src]="assetPath + 'recommendation/2.png'" class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img [src]="assetPath + 'recommendation/3.png'" class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img [src]="assetPath + 'recommendation/4.png'" class="d-block w-100" alt="..." />
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselRecommendation"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselRecommendation"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pb-5 recommendation">
    <div class="recommendation-box container">
      <h3 class="fs-36 text-championPurple mb-4">
        Our recommendation models
      </h3>
      <div class="row row-strech">
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <div class="card-carousel h-100">
            <div class="card-header">
              <img [src]="assetPath + 'slider01.png'" alt="" />
            </div>
            <div class="card-body">
              <h4 class="fs-26 text-championPurple">Solar</h4>
              <p class="fs-16 text-bodyGrey mb-0">
                Our model generates recommendations with the quantity, investment, and savings view based on your site
                roof area, location and degree days.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <div class="card-carousel h-100 mb-4 mb-lg-0">
            <div class="card-header">
              <img [src]="assetPath + 'slider02.png'" alt="" />
            </div>
            <div class="card-body">
              <h4 class="fs-26 text-championPurple">Insulation</h4>
              <p class="fs-16 text-bodyGrey mb-0">
                We can detect the age of the building and the building fabric to create insulation recommendations for
                your floor, walls, roof, and windows.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <div class="card-carousel h-100 mb-4 mb-lg-0">
            <div class="card-header">
              <img [src]="assetPath + 'slider03.png'" alt="" />
            </div>
            <div class="card-body">
              <h4 class="fs-26 text-championPurple">Lighting</h4>
              <p class="fs-16 text-bodyGrey mb-0">
                Based on the benchmarks for building of similar types and age, we generate the product and quantity
                recommendation.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="card-carousel h-100">
            <div class="card-header">
              <img [src]="assetPath + 'slider04.png'" alt="" />
            </div>
            <div class="card-body">
              <h4 class="fs-26 text-championPurple">
                Boiler and heatpump
              </h4>
              <p class="fs-16 text-bodyGrey mb-0">
                We can detect the heating and hot water splits based on the age of the building, type of building
                fabrics, and the benchmark of similar businesses. From this, we can suggest the size of the system and
                investment needed, the potential payback period, and the return on investment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5 bg-championPurple d-none">
    <div id="carouselExampleIndicators-feature" class="carousel slide factual-carousel" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators-feature" data-bs-slide-to="0" class="active"
          aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators-feature" data-bs-slide-to="1"
          aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators-feature" data-bs-slide-to="2"
          aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators-feature" data-bs-slide-to="3"
          aria-label="Slide 4"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="5000">
          <div class="container">
            <div class="col-lg-10 mx-auto d-flex features">
              <div class="img-box pe-lg-5 me-lg-5 pe-3 me-3 border-end">
                <img [src]="assetPath + 'rec01.svg'" alt="" />
              </div>
              <div class="content-box">
                <h4 class="fs-26 text-white mb-4">Factual</h4>
                <p class="fs-18 text-white mb-4">
                  Based on your realtime meter data and EPC.
                </p>
                <button class="btn-link-alt">Get started</button>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <div class="container">
            <div class="col-lg-10 mx-auto d-flex features">
              <div class="img-box pe-lg-5 me-lg-5 pe-3 me-3 border-end">
                <img [src]="assetPath + 'rec02.svg'" alt="" />
              </div>
              <div class="content-box">
                <h4 class="fs-26 text-white mb-4">Minimal data inputs</h4>
                <p class="fs-18 text-white mb-4">
                  We only need your address.
                </p>
                <button class="btn-link-alt">Get started</button>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <div class="container">
            <div class="col-lg-10 mx-auto d-flex features">
              <div class="img-box pe-lg-5 me-lg-5 pe-3 me-3 border-end">
                <img [src]="assetPath + 'rec03.svg'" alt="" />
              </div>
              <div class="content-box">
                <h4 class="fs-26 text-white mb-4">Tailored recommendation</h4>
                <p class="fs-18 text-white mb-4">
                  Tailored based on the business type, site location and benchmarks for similar tech.
                </p>
                <button class="btn-link-alt">Get started</button>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <div class="container">
            <div class="col-lg-10 mx-auto d-flex features">
              <div class="img-box pe-lg-5 me-lg-5 pe-3 me-3 border-end">
                <img [src]="assetPath + 'rec04.svg'" alt="" />
              </div>
              <div class="content-box">
                <h4 class="fs-26 text-white mb-4">One stop shop</h4>
                <p class="fs-18 text-white mb-4">
                  We aggregate OEMs and installers in one place.
                </p>
                <button class="btn-link-alt">Get started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer class="bg-championPurple">
    <div class="container text-white py-4">
      <div class="text-center">
        <div class="p-3">
          <a class="GA-term mx-2 text-white link" target="_blank" role="button" [href]="tncUrl">Terms and conditions</a>
          <span>|</span>
          <a class="mx-2 text-white link" href="https://perse.io/privacy-policy" target="_blank">Privacy
            policy</a><br />
          Share your feedback with us by emailing
          <a class="text-white link" href="mailto:business@perse.energy">business@perse.energy</a>
        </div>
      </div>
    </div>
  </footer>
</main>
<!-- Modal -->
<ng-template #viewModal let-c="close" let-d="dismiss">
  <app-login class="w-100"></app-login>
</ng-template>