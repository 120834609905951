import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface ModalDataConfig{
  title?: string;
  header: string;
  body: string;
  actionBtnTitle?: string;
  closeButtonTitle: string;
  enableInput?: boolean;
  isLoading?: boolean;
  inputValue?: string;
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Output() onActionClick = new EventEmitter<string | boolean>();
  @Input() successMessage: string = ''
  @Input() errorMessage: string = ''
  @Input() modalDataConfig: ModalDataConfig = {
    actionBtnTitle: '',
    body: '',
    closeButtonTitle: 'Close',
    enableInput: false,
    header: '',
    isLoading: false,
    title: '',
    inputValue: ''
  }

  constructor(private modalService: NgbModal) { }

  close(): void {
    this.modalService.dismissAll();
  }

  doAction(): void {
    this.onActionClick.emit(this.modalDataConfig.enableInput ? this.modalDataConfig.inputValue : true);
  }

}
