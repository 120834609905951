<!-- Email verification modal -->
<div class="px-lg-5 py-3 py-lg-4 py-xxl-5">
    <span class="fs-20 text-secondary">{{modalDataConfig.title}}</span>
    <h1 class="fs-40 text-brightPurple mb-3">{{ modalDataConfig.header }}</h1>
    <p class="fs-20 text-secondary mb-3 mb-lg-4" [innerHTML]="modalDataConfig.body"></p>
    <div *ngIf="modalDataConfig.enableInput" class="mb-4 mt-4">
        <input type="text" class="form-control inputTyp1" id="cNameManual" placeholder="Enter here"
            [(ngModel)]="modalDataConfig.inputValue" />
    </div>
    <p *ngIf="successMessage" class="text-success mb-0 fw-semibold">{{ successMessage }}</p>
    <p *ngIf="errorMessage" class="text-danger mb-0 fw-semibold">{{ errorMessage }}</p>
    <div class="d-grid d-md-flex justify-content-md-end gap-3 mt-2">
        <button type="button" class="GA-registerClose btn-secondary" (click)="close()">
            {{modalDataConfig.closeButtonTitle || 'Close' }}
        </button>
        <button *ngIf="modalDataConfig.actionBtnTitle" [disabled]="modalDataConfig.enableInput && !modalDataConfig.inputValue" type="button"
            class="GA-resetLink btn-primary" (click)="doAction()">
            {{modalDataConfig.actionBtnTitle}}
        </button>
    </div>
</div>
<!-- Email verification modal end-->