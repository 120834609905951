import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import Constants from '../constants/constants';

import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  repaint$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  currentUser: Observable<any> = this.currentUserSubject.asObservable();
  update$: Observable<any> = this.repaint$.asObservable();

  constructor(
    private sessionService: SessionService
  ) {}

  // Is loggedIn user
  isLoggedUser(): boolean {
    return !!this.sessionService.getItemFromSession(Constants.sessionConstants.EMAIL);
  }
}
