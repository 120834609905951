import { Injectable } from '@angular/core';

import { Buffer } from 'buffer';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import Constants from '../constants/constants';

import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  subjectChange = new BehaviorSubject<number>(0);

  constructor(
    private sessionService: SessionService
  ) { }

  // Set the value of the subjectChange
  setSubjectChange(value: number) {
    this.subjectChange.next(value);
  }

  // Get the value of the subjectChange
  getSubjectChange() {
    return this.subjectChange.asObservable();
  }

  /**
   * 
   * @param value: number
   * @description Creating a boolean function to determine if a number is a decimal or a whole number 
   * @returns boolean
   */
  isDecimalValue(value: number): boolean {
    return (value % 1 === 0) ? false : true;
  }


  /**
   * @description Get list of all the property types
   * @returns any[] list of property types
   */
  getListOfPropertyTypes(): any[] {
    return [
      { name: 'A1/A2 Retail and Financial/Professional services' },
      { name: 'A3/A4/A5 Restaurant and Cafes/Drinking Establishments and Hot Food takeaways' },
      { name: 'Airport terminals' },
      { name: 'B1 Offices and Workshop businesses' },
      { name: 'B2 to B7 General Industrial and Special Industrial Groups' },
      { name: 'B8 Storage or Distribution' },
      { name: 'Bus station/train station/seaport terminal' },
      { name: 'C1 Hotels' },
      { name: 'C2 Residential Institutions - Hospitals and Care Homes' },
      { name: 'C2 Residential Institutions - Residential schools' },
      { name: 'C2 Residential Institutions - Universities and colleges' },
      { name: 'C2A Secure Residential Institutions' },
      { name: 'Community/day centre' },
      { name: 'Crown and county courts' },
      { name: 'D1 Non-residential Institutions - Community/Day Centre' },
      { name: 'D1 Non-residential Institutions - Crown and County Courts' },
      { name: 'D1 Non-residential Institutions - Education' },
      { name: 'D1 Non-residential Institutions - Libraries Museums and Galleries' },
      { name: 'D1 Non-residential Institutions - Primary Health Care Building' },
      { name: 'D2 General Assembly and Leisure plus Night Clubs and Theatres' },
      { name: 'Dwelling' },
      { name: 'Emergency services' },
      { name: 'Further education universities' },
      { name: 'Hospital' },
      { name: 'Hotel' },
      { name: 'Industrial process building' },
      { name: 'Launderette' },
      { name: 'Libraries/museums/galleries' },
      { name: 'Miscellaneous 24hr activities' },
      { name: 'Nursing residential homes and hostels' },
      { name: 'Office' },
      { name: 'Others - Car Parks 24 hrs' },
      { name: 'Others - Emergency services' },
      { name: 'Others - Miscellaneous 24hr activities' },
      { name: 'Others - Passenger terminals' },
      { name: 'Others -Telephone exchanges' },
      { name: 'Others - Stand alone utility block' },
      { name: 'Primary health care buildings' },
      { name: 'Primary school' },
      { name: 'Prisons' },
      { name: 'Residential spaces' },
      { name: 'Restaurant/public house' },
      { name: 'Retail' },
      { name: 'Retail warehouses' },
      { name: 'Secondary school' },
      { name: 'Social clubs' },
      { name: 'Sports centre/leisure centre' },
      { name: 'Sports ground arena' },
      { name: 'Telephone exchanges' },
      { name: 'Theatres/cinemas/music halls and auditoria' },
      { name: 'Warehouse and storage' },
      { name: 'Workshops/maintenance depot' }
    ];
  }

  /**
   * @description Get list of all age of building
   * @returns any[] list of age of building
   */
  getAgeOfBuilding(): any[] {
    return [
      { year: '<1899' },
      { year: '1900-29' },
      { year: '1930-39' },
      { year: '1940-49' },
      { year: '1950-69' },
      { year: '1970-79' },
      { year: '1980-89' },
      { year: '1990-99' },
      { year: '2000-2012' },
      { year: '2013-' },
    ];
  }

  /**
   * @description Redirection link to the DFS
   * @param payload any
   * @returns string
   */
  encodeRedirectionLink(payload: any): string {
    return environment.dfsUrl + '/auth?redirect=' + encodeURIComponent(Buffer.from(JSON.stringify(payload)).toString('base64'));
  }

  /**
   * 
   * @description Get the host name from the session and split it to get the first two parts of the host name
   * @returns string
   */
  get host(): string {
    const hostName = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.host || window.location.host;

    // Split the host name to get the first two parts of the host name
    const hostSplit = hostName?.split('.');
    // For Local
    if (hostName === 'localhost:4200') {
      return './assets/images/energyhelp/';
      // energyhelp
      // consultivutilitiesenergyhelp
      // advantageutilitiesenergyhelp
      // jlmelectricalenergyhelp
      // utilityaidenergyhelp
    } else {
      if (hostSplit?.length > 3) {
        // Check if the length of the hostSplit is greater than 3. If it is, return the first two parts of the host name.
        return environment?.assets + hostSplit[0] + '/' + hostSplit[1] + '/images/';
      } else {
        // If the length of the hostSplit is not greater than 3, return the first part of the host name.
        return environment?.assets + hostSplit[0] + '/prod/images/';
      }
    }
  }

  get config(): any {
    const hostName = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.host || window.location.host;
    const hostSplit = hostName?.split('.');
    // For Local
    if (hostName === 'localhost:4200') {
      return './assets/json/energyhelp/config.json';
      // energyhelp
      // consultivutilitiesenergyhelp
      // advantageutilitiesenergyhelp
      // jlmelectricalenergyhelp
      // utilityaidenergyhelp
    } else {
      return environment?.assets + hostSplit[0] + '/json/config.json';
    }
  }

  /**
   * @description Configure the application
   */
  configureApplication(): void {
    const configuration = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.config;

    if (!!configuration) {
      Object.keys(configuration).forEach((key) => {
        document.documentElement.style.setProperty(`--${key}`, configuration[key]);
        document.documentElement.style.setProperty(`--${key}`, configuration[key]);
      });
    }
  }

  /**
   * Save or download file from `Blob` object
   * 
   * @param {Blob|MediaSource} blob - The `Blob` or `MediaSource` object to be downloaded.
   * @param {string} filename - The name of the file to be saved.
   * @returns {void}
   */
  saveFile(blob: Blob | MediaSource, filename: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}