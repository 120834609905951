import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import Constants from '../constants/constants';

import { SessionService } from './session.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private titleService: Title,
    private sessionService: SessionService,
    private http: HttpClient,
    private util: UtilService
  ) { }

  // Load Confiration
  loadConfig(): void {
    let info: any = {};
    // set the host
    info['host'] = window.location.host;
    // Sample Host
    // energyhelp.dev.perse.io
    // consultivutilitiesenergyhelp.dev.perse.io
    // advantageutilitiesenergyhelp.dev.perse.io
    // jlmelectricalenergyhelp.dev.perse.io
    info['origin'] = window.location.origin;
    // Sample Origin
    // https://energyhelp.dev.perse.io
    // https://consultivutilitiesenergyhelp.dev.perse.io
    // https://advantageutilitiesenergyhelp.dev.perse.io
    // https://jlmelectricalenergyhelp.dev.perse.io
    // Store into session
    this.sessionService.setItemIntoSession(Constants.CONFIG.INFO, info);
    // Set page title
    const prefixTitle = (info['host']?.split('.')[0]);
    // Only for energyhelp
    if (prefixTitle === 'energyhelp') {
      info['source'] = 'energyhelp';
      info['title'] = 'Energy Help';
      // Set into browser title
      this.titleService.setTitle(info['title']);
      // Store into session
      this.sessionService.setItemIntoSession(Constants.CONFIG.INFO, info);
    } else {
      // For other domains - Consultiv and Advantage Utilities
      const titleSplit = (info['host']?.split('.')[0]).split('energyhelp');
      info['source'] = (titleSplit[0] === 'localhost:4200') ? 'testing' : titleSplit[0];
      info['title'] = (info['source'] === 'testing') ? '' : (info['source']?.toUpperCase());
      // Set into browser title
      this.titleService.setTitle(info['title'] + ' Energy Help');
      // Store into session
      this.sessionService.setItemIntoSession(Constants.CONFIG.INFO, info);
    }

    // Set favicon
    const favicon = document.createElement('link');
    favicon.href = this.util.host + 'favicon.ico';
    favicon.type = 'image/x-icon';
    favicon.rel = 'icon';
    document.head.appendChild(favicon);

    // Set the configuration
    this.http.get(this.util.config).subscribe((config: any) => {
      info['config'] = config;
      this.sessionService.setItemIntoSession(Constants.CONFIG.INFO, info);
      this.util.configureApplication();
    });
    return;
  }
}