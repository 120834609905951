import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  /**
 * @param key string
 * @param value any
 * @description Store date into client session
 */
  setItemIntoSession(key: string, value: any): void {
    if (!key) {
      throw new Error('Key and Value are mandatory fields and neither be empty nor blank.');
    }

    // Store into client session
    sessionStorage.setItem(key, (typeof value == 'string') ? value : JSON.stringify(value));
  }

  /**
   * 
   * @param key string
   * @description Get data from client session
   */
  getItemFromSession(key: string): any {
    if (!!sessionStorage.getItem(key)) {
      // Get from client session
      try {
        return JSON.parse(sessionStorage.getItem(key)!);
      } catch (e) {
        return sessionStorage.getItem(key)
      }
    }
  }

  /**
   * 
   * @param key string
   * @description Remove data from respective key
   */
  removeItemFromSession(key: string): void {
    // Remove from client session
    if (!!sessionStorage.getItem(key)) {
      sessionStorage.removeItem(key);
    }
  }


  /**
   * @param key string
   * @param value any
   * @description Store date into client local storage
   */
  setItemIntoLocal(key: string, value: any): void {
    if (!key) {
      throw new Error('Key and Value are mandatory fields and neither be empty nor blank.');
    }

    // Store into client local storage
    localStorage.setItem(key, (typeof value == 'string') ? value : JSON.stringify(value));
  }

  /**
   * 
   * @param key string
   * @description Get data from client local storage
   */
  getItemFromLocal(key: string): any {
    if (!!localStorage.getItem(key)) {
      // Get from client local storage
      try {
        return JSON.parse(localStorage.getItem(key)!);
      } catch (e) {
        return localStorage.getItem(key)
      }
    }
  }

  /**
   * 
   * @param key string
   * @description Remove data from respective key
   */
  removeItemFromLocal(key: string): void {
    // Remove from client local storage
    if (!!localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  }

  /**
   * @description Clear client session storage
   */
  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  /**
   * @description Clear client local storage
   */
  clearLocalStorage(): void {
    localStorage.clear();
  }
}
