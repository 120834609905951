<div class="px-lg-5 py-3 py-lg-4 py-xxl-5">
    <span class="fs-20 text-secondary">Add your</span>
    <h1 class="fs-40 text-headingGrey mb-3">Company details</h1>
    <div class="fs-20 text-secondary mb-3 mb-lg-4">
        To generate recommendations, we need access to your energy details. Please provide your company information to create the necessary Letter of Authority (LOA)
    </div>

    <form [formGroup]="companyForm" (keyup.enter)="onSubmit()">
        <div class="mb-3 search-container position-relative">
            <label class="form-label text-headingGrey mb-1 fs-12" for="">Company name</label>
            <ng-select (change)="selectCompany($event)" [virtualScroll]="true" [clearable]="false" (search)="getCompanyDetails($event)"
                [loading]="isCompanyLoading" 
                formControlName="companyName"
                placeholder="Start by typing your company name"
                typeToSearchText="Please enter 3 or more char"
                [minTermLength]="3"
                >
                <ng-option *ngFor="let item of listOfCompanies" [value]="item">{{item.name }}</ng-option>
            </ng-select>
            <p *ngIf="getFormControl('companyName')?.touched && getErrorControl('companyName')?.['required']" class="text-danger fs-14 mb-0">
                Company Name is required
            </p>
        </div>

        <div class="mb-3" *ngIf="!!isCompanyAddressLoading">
            <p class="placeholder-wave">
                <span class="placeholder placeholder-xs bg-bodyGrey w-25"></span>
                <span class="placeholder placeholder-sm bg-bodyGrey w-100"></span>
            </p>
        </div>

        <div class="mb-3" *ngIf="!isCompanyAddressLoading && !!isCompanyAddress">
            <label class="form-label text-headingGrey mb-1 fs-12" for="">Company address</label>
            <p class="mb-0">{{ companyAddress }}</p>
        </div>

        <ng-container *ngIf="isManualCompany">
            <div class="mb-3" *ngIf="!isCompanyNoAddress">
                <label class="form-label text-headingGrey mb-1 fs-12" for="">Company name(Enter manually)</label>
                <input type="text" class="form-control inputTyp1" id="cNameManual" placeholder="Enter company name"
                    formControlName="companyNameManual" />
                <p *ngIf="getFormControl('companyNameManual')?.touched && getErrorControl('companyNameManual')?.['required']" class="text-danger fs-14 mb-0">
                    Company Name is required
                </p>
            </div>

            <div class="mb-3 search-container position-relative">
                <label class="form-label text-headingGrey mb-1 fs-12" for="">Company Type</label>
                <ng-select [clearable]="false"
                    formControlName="companyType"
                    placeholder="Select company type"
                    [items]="companyTypes"
                    bindLabel="display_name"
                    bindValue="name"
                    >
                </ng-select>
                <p *ngIf="getFormControl('companyType')?.touched && getErrorControl('companyType')?.['required']" class="text-danger fs-14 mb-0">
                    Company type is required
                </p>
            </div>

            <div class="mb-3">
                <label class="form-label text-headingGrey mb-1 fs-12" for="">Company number</label>
                <input type="text" class="form-control inputTyp1" placeholder="Enter company number"
                    formControlName="companyNumber" />
                <p *ngIf="getFormControl('companyNumber')?.touched && getErrorControl('companyNumber')?.['required']" class="text-danger fs-14 mb-0">
                    Company number is required
                </p>
            </div>

            <div class="mb-3 search-container position-relative">
                <label class="form-label text-headingGrey mb-1 fs-12" for="addressDropdown">Search company address</label>
                <ng-select id="addressDropdown" (change)="selectedGBGAddress($event)" 
                    [virtualScroll]="true" 
                    [clearable]="true" 
                    (search)="searchFreeText($event)"
                    formControlName="companyAddress"
                    placeholder="Start by typing your address or postcode." 
                    [loading]="isAddressLoading"
                    [minTermLength]="3">
                    <ng-option *ngFor="let address of listOfAddress" [value]="address">{{ address.fullAddress }}</ng-option>
                </ng-select>
                <p class="my-2 text-danger fs-14" *ngIf="!!invalidAddress">
                    {{ invalidAddress }}
                </p>
                <p *ngIf="getFormControl('companyAddress')?.touched && getFormControl('companyAddress')?.errors?.['required']" class="text-danger fs-14 mb-0">
                    Company address is required
                </p>
            </div>

            <div formGroupName="address" *ngIf="companyForm?.get('address') && !!isCompanyAddressNotInList">
                <div class="mb-3">
                    <label class="form-label text-headingGrey mb-1 fs-12" for="">Locality</label>
                    <input type="text" class="form-control inputTyp1" formControlName="locality" autocomplete="off" />
                    <p *ngIf="getFormControl('address')?.get('locality')?.touched && getFormControl('address')?.get('locality')?.errors?.['required']" class="text-danger fs-14 mb-0">
                        Locality is required
                    </p>
                </div>
                <div class="mb-3">
                    <label class="form-label text-headingGrey mb-1 fs-12" for="">Address 1</label>
                    <input type="text" class="form-control inputTyp1" formControlName="address_line_1"
                        autocomplete="off" />
                    <p *ngIf="getFormControl('address.address_line_1')?.touched && getFormControl('address.address_line_1')?.errors?.['required']" class="text-danger fs-14 mb-0">
                        Address is required
                    </p>
                </div>
                <div class="mb-3">
                    <label class="form-label text-headingGrey mb-1 fs-12" for="">Address 2</label>
                    <input type="text" class="form-control inputTyp1" formControlName="address_line_2"
                        autocomplete="off" />
                </div>
                <div class="mb-3">
                    <label class="form-label text-headingGrey mb-1 fs-12" for="">Country</label>
                    <input type="text" class="form-control inputTyp1" formControlName="country" autocomplete="off" />
                    <p *ngIf="getFormControl('address.country')?.touched && getFormControl('address.country')?.errors?.['required']" class="text-danger fs-14 mb-0">
                        Country is required
                    </p>
                </div>
                <div class="mb-3">
                    <label class="form-label text-headingGrey mb-1 fs-12" for="">Postcode</label>
                    <input type="text" class="form-control inputTyp1" formControlName="postal_code"
                        autocomplete="off" />
                    <span class="text-danger fs-14 mt-1"
                        *ngIf="companyForm.get('address.postal_code')?.errors?.['pattern']">Please
                        enter a valid postcode.</span>
                    <p *ngIf="getFormControl('address.postal_code')?.touched && getFormControl('address.postal_code')?.errors?.['required']" class="text-danger fs-14 mb-0">
                        Postcode is required
                    </p>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="mb-2 text-center text-danger" *ngIf="!!errorMessage">
        {{ errorMessage }}
    </div>
    <p *ngIf="successMessage" class="mb-2 text-success mb-0 fw-semibold">{{ successMessage }}</p>
    <div class="text-center">
        <button type="button" class="GA-register btn-primary w-100"
            [disabled]="companyForm.invalid" (click)="onSubmit()">
            Send link
        </button>
        <button type="button" class="mt-2 GA-registerClose btn-secondary w-100" (click)="close()">
            Close
        </button>
    </div>
</div>