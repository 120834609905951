<div class="">
  <div class="container">
    <a class="d-block d-md-none logo pt-2" role="button">
      <img [src]="assetPath + 'logo.svg'" alt="" />
    </a>
    <h1 class="GA-logo landingLogo d-none d-md-block">
      <img [src]="assetPath + 'logo.svg'" alt="" title="" />
    </h1>

    <div class="row">
      <div class="col-12 col-md-10">
        <h2 class="text-brightPurple fs-30 fw700 my-4">Terms & Conditions</h2>
        <ol>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">General</h3>
            <ol>
              <li>These Terms and Conditions apply to any of our services which may help homes and businesses save money and carbon in relation to their premises in Britain (the “Service”). By entering any Service(s), you agree to be bound by the following Terms and Conditions.</li>
              <li>The Service will be run by Perse Technology Ltd, a company registered in England and Wales with registered number 12447140 and registered office 318 Soho Square, London W1D 3QL (“Perse”, “Labrador”, “We”, “Us”). </li>
              <li>The Service is offered via this (“<a href="https://energyhelp.perse.io" target="_blank"
                  class="text-col1 text-decoration-none fw700">website</a>”) and/ or other domains shared with you. </li>
              <li>We reserve the right to cancel or amend the Service and/or these Terms without prior notice. Any changes will be posted on our website.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Using the Services</h3>
            <ol>
              <li>In order to use any of the Services, you will need to register with Us via the Site. Perse may, in our
                sole discretion, refuse to register anyone as a Customer.</li>
              <li>In order to be eligible to register to use the Services, you must:
                <ol>
                  <li>be a person or business that is resident in the UK;</li>
                  <li>be aged eighteen years or over; and </li>
                  <li>be able to provide Perse with all such relevant and accurate information as we may require, in
                    order to provide the Services to you.</li>
                </ol>
              </li>

            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Provision of Services</h3>
            <ol>
              <li>This service intends to help you better understand the gas and electricity usage of your building(s)
                and steps you could take to reduce cost and carbon. The accuracy of our information and recommendations
                depends on you providing us with correct address information and correcting any information that is not
                correct. If you need to correct anything, please contact us at <a href="mailto:contact@perse.energy"
                  class="text-col1 text-decoration-none fw700">contact@perse.energy</a>. The information that we
                retrieve from industry sources, based on your address, is the same information that your energy
                suppliers use to bill you on. This is not data that Perse has created nor estimated - we rely on the
                industry data as being accurate. Our Service also assumes that your energy usage will continue to the
                same levels as it has in the last 12 months. If you believe your use will change, this will likely
                affect the accuracy of our insights and recommendations. Please contact us at
                <a href="mailto:contact@perse.energy"
                  class="text-col1 text-decoration-none fw700">contact@perse.energy</a> if you think this applies to
                you.
              </li>
              <li>Whilst we strive our best to provide accurate and personalised information to help you make informed
                decisions, we cannot guarantee outcomes. If you decide to act upon any of the information provided to
                you via our Service, this is done at your sole risk and responsibility.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-col6">Demand Flexibility Service (DFS)</h3>
            <ol>
              <li>To be eligible for this service, you need to:
                <ol>
                  <li>have a smart meter or if you’re a business, an advanced half hourly meter, and give us consent to
                    access your half hourly data;</li>
                  <li>give us permission to share this data with the National Grid ESO (ESO) in order that they can
                    reconcile any flexibility provided and monies due; </li>
                  <li>not be registered with another DFS provider that you have signed up to after joining our Service;
                    and</li>
                  <li>successfully reduce your electricity consumption in the DFS event times, as we will communicate
                    with you.</li>
                </ol>
              </li>
              <li>
                The date, timing and frequency of any DFS event is in the sole discretion of the ESO. Eligibility for
                payment is also in the sole discretion of the ESO. Full terms of their service are here: <a
                  href="https://www.nationalgrideso.com/industry-information/balancing-services/demand-flexibility-service-dfs#Contractual-and-guidance-documents-(2023/24)">https://www.nationalgrideso.com/industry-information/balancing-services/demand-flexibility-service-dfs#Contractual-and-guidance-documents-(2023/24)</a>
              </li>
              <li>Our provision of this Service is in accordance with ESO’s Procurement Rules, which can be read here:
                <a
                  href="https://www.nationalgrideso.com/industry-information/balancing-services/demand-flexibility-service-dfs#Contractual-and-guidance-documents-(2023/24)">https://www.nationalgrideso.com/industry-information/balancing-services/demand-flexibility-service-dfs#Contractual-and-guidance-documents-(2023/24)</a>
              </li>
              <li>We will endeavour to give you notice of any DFS event and to register you to participate but we do not
                guarantee the same. We also endeavour to provide the ESO with accurate forecasts to optimise the chance
                of having bids accepted, but we cannot guarantee accuracy nor what ESO will accept. </li>
              <li>We will communicate to you the amount of electricity usage that we believe you have saved during any
                DFS event and the monies that we believe you will be due. However, we rely on the accuracy of industry
                data and the ESO’s decision on amounts to be paid and the timing of payments. </li>
              <li>The timing and method of any payments, once monies are received from the ESO, shall be at our
                discretion. However, we intend to provide you with the option to choose to draw down funds once minimum
                levels are reached, from your customer account.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Charges</h3>
            <ol>
              <li>Save and except our demand flexibility service with the ESO, this Service is provided free of charge to you. We may receive payment from another service provider that we are working with, such as your bank. We may also receive payment from provider(s) if you choose to take up a recommendation that involves one of their goods and/ or services. In the event we receive a payment, we will never recommend the provider over another unless it is genuinely appropriate to your needs.  
</li>
                <li>With the demand flexibility service with ESO, we will charge a transaction fee that will be deducted from any payment to you. There is no charge unless you are eligible for a payment. Our transaction fee will be communicated to you by us or by our partner, who may be coordinating this service with you.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Intellectual property rights</h3>
            <ol>
              <li>We are the owner or the licensee of all intellectual property rights in our Websites and the Services. No right to use any of our intellectual property is granted save as strictly necessary to use the Services. </li>
              <li>You acknowledge and agrees that your details may be shared with your bank, or other service provider (including intermediary or agent) who has introduced you to our service, and personal data will be processed by and on your behalf in accordance with our Privacy Policy: <a href="https://perse.io/privacy-policy">https://perse.io/privacy-policy</a> We shall be entitled to share data with your bank or other service provider. You should also check your bank or other service provider’s privacy policy as well. </li>
              <li>You grant us permission to investigate your energy supply details on the relevant industry databases (which may include but not be limited to RECCo, Xoserve, ECOES, Electralink, the Data Communications Company, n3rgy, Companies House and HMRC) in order to provide any information. This includes, where available, your energy usage history for up to 3 years.</li>
            </ol>
          </li>

          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Termination</h3>
            <ol>
              <li>Without prejudice to the foregoing and any other rights and remedies that we may have, we shall be
                entitled to terminate or suspend the Services.</li>
              <li>You hereby agrees to indemnify, keep indemnified, defend and hold us and our parent companies,
                subsidiaries, affiliates and each of their respective officers, directors, employees, owners, agents,
                suppliers, contractors, partners, information providers and licensors harmless from and against any and
                all claims, damages, liability, demands, losses, costs and expenses (including legal fees) (whether or
                not foreseeable or avoidable) incurred or suffered by any of such parties and any claims or legal
                proceedings which are brought or threatened arising out of or in connection with any use by or conduct
                of you in relation to any of the Services, any transactions, dealings or arrangements made with any
                third party as a result of using the Services or any breach of any of the provisions of these Terms or
                of any law or the rights of any third party.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Limitation on Liability</h3>
            <ol>
              <li>We will exercise all reasonable skill and care in providing the Services. However, the performance of
                the Services by us is dependent upon third parties (including, without limitation, industry providers of
                gas and electricity data) and we are not able to guarantee or accept any responsibility for any failure
                or delay caused by such third parties or for any inaccurate, incomplete or unreliable information
                provided to you by such parties via our Service.</li>
              <li>We shall use our reasonable endeavours to ensure that all pricing information provided by us to you as
                part of the Service is accurate, current and reliable in all material respects. However, save in respect
                of the foregoing, we do not warrant accuracy and we exclude all liability in respect of the accuracy,
                completeness, fitness for purposes or legality of any information accessed as a result of your use of
                the Services or the Site or otherwise communicated by us to you.</li>
              <li>Except as expressly provided in these Terms, the Services and the Site are provided on an “as is”
                basis without representation or warranty of any kind and to the fullest extent permissible pursuant to
                applicable law we disclaim all other conditions, representations, statements and warranties, either
                express or implied (whether by common law, custom, statute or otherwise).</li>
              <li>You acknowledge and agree that we shall not be liable for:
                <ul>
                  <li>any indirect loss, claim or damage, or any punitive, special, incidental or consequential damages
                    of any kind;</li>
                  <li>any loss of profit or savings;</li>
                  <li>loss or corruption of data or information;</li>
                  <li>loss of contracts, business or opportunity;</li>
                  <li>damage to goodwill or reputation(s); in each case whether direct or indirect and in each case
                    whether based in contract, tort (including without limitation negligence), strict liability, or
                    otherwise, arising out of or in connection with these Terms, the Services, the Site and/or any use
                    thereof, in each case even if Perse has been forewarned or is aware of the possibility of such loss
                    or damage.</li>
                </ul>
              </li>
              <li>
                We do not exclude or limit its liability (if any) in any way:
                <ul>
                  <li>for death or personal injury caused by our negligence;</li>
                  <li>for fraud or fraudulent misrepresentation; or</li>
                  <li>for any matter from which it is unlawful to exclude, or attempt to exclude, our liability.</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Force majeure</h3>
            <ol>
              <li>We shall have no liability to you if we are prevented from or delayed in performing any of our
                obligations in relation to the provision of any of the Services, or from carrying on our business, by
                acts, events, omissions or accidents beyond our reasonable control, including (without limitation)
                strikes, lock-outs or other industrial disputes (whether involving the workforce of Perse or any other
                party), failure of a utility service or transport network, act of God, war, riot, civil commotion,
                malicious damage, compliance with any law or governmental order, rule, regulation or direction,
                accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or subcontractors,
                and Labrador shall be entitled to a reasonable extension of the time for performing such obligations in
                the event of any such occurrence.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">No waiver</h3>
            <ol>
              <li>Any failure or delay by us to enforce any of our rights under these Terms is not to be taken as or
                deemed to be a waiver of that or any other right unless we acknowledge and agree to such a waiver in
                writing.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Severability</h3>
            <ol>
              <li>If any clause or part of a clause of these Terms is, or becomes, invalid, illegal or unenforceable,
                then that clause or part of a clause shall be deemed to be deleted from these Terms. Any such deemed
                deletion shall not affect the validity, legality or enforceability of the remainder of these Terms.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Third Party Rights</h3>
            <ol>
              <li>Except as expressly provided, the provisions of these Terms are personal to you and are not intended
                to confer any rights of enforcement on any other third party. The Contracts (Rights of Third Parties)
                Act 1999 shall not apply to this Contract or to any of its provisions, other than clause 7.2.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Transfer of rights and obligations</h3>
            <ol>
              <li>These Terms are binding on you and us and on each parties’ respective successors and assigns.</li>
              <li>You may not transfer, assign, charge or otherwise dispose of these Terms, or any of your rights or
                obligations arising under them, without our prior written consent.</li>
              <li>We may at any time transfer, assign, charge, sub-contract or otherwise dispose of these Terms, or any
                of its rights or obligations arising under them.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Complaints
            </h3>
            <ol>
              <li>We aim to provide you with excellent service at all times and we are committed to continuous
                improvement. Please contact us at <a href="mailto:contact@perse.energy"
                  class="text-col1 text-decoration-none fw700">contact@perse.energy</a> if you need help or have
                recommendations.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Entire Agreement
            </h3>
            <ol>
              <li>The warranties, exclusions and other express provisions of these Terms and our Privacy Policy set out
                the full extent of our obligations and liabilities concerning the subject matter and supersede any
                previous agreements between the parties relating thereto.</li>
            </ol>
          </li>
          <li>
            <h3 class="fs-20 fw600 text-bodyGrey">Governing Law and Jurisdiction</h3>
            <ol>
              <li>These Terms are governed by the laws of England and Wales and the parties agree to submit to the
                non-exclusive jurisdiction of the English Courts.</li>
            </ol>
          </li>
        </ol>

        <p>These Terms and Conditions were last updated on 6 February 2024.</p>


      </div>
    </div>
  </div>
</div>