import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry, timeout } from 'rxjs';

import { environment } from 'src/environments/environment';
import Constants from '../constants/constants';

import { NgxSpinnerService } from 'ngx-spinner';

import { SessionService } from './session.service';
import { UtilService } from './util.service';
import { Recommendation } from '../models/recommendation.interface';

@Injectable({
  providedIn: 'root',
})
export class RestAPIsService {
  // private internetIssue: any = 'Ooops! You are not connected internet. Please check.';
  // Domestic API
  private domesticAPIUrl: string = environment.domesticAPIUrl;

  // Business API
  private businessAPIUrl: string = environment.businessAPIUrl;

  // Prod API
  private prodAPIUrl: string = environment.prodAPIUrl;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private sessionService: SessionService,
    private util: UtilService
  ) { }

  // GET API
  getAPI(path: string, condition?: string): Observable<any> {
    return this.http.get((condition === 'domestic' ? this.domesticAPIUrl : (condition === 'other') ? '' : this.businessAPIUrl) + path)
      .pipe(retry(2), timeout(30000));
  }

  // POST API
  postAPI(path: string, body: any, condition?: string): Observable<any> {
    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post((condition === 'domestic' ? this.domesticAPIUrl : (condition === 'prod') ? this.prodAPIUrl : this.businessAPIUrl) + path,
      body,
      httpOptions
    ).pipe(retry(2), timeout(30000));
  }

  // POST API for download PDF
  postAPIForPdf(path: string, body: any, condition?: string): Observable<any> {
    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob'
    };
    return this.http.post((condition === 'domestic' ? this.domesticAPIUrl : this.businessAPIUrl) + path,
      body,
      httpOptions
    ).pipe(retry(2), timeout(30000));
  }

  // POST API
  putAPI(path: string, body: any, condition?: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put((condition === 'domestic' ? this.domesticAPIUrl : this.businessAPIUrl) + path,
      body,
      httpOptions
    ).pipe(retry(2), timeout(30000));
  }

  // POST API
  deleteAPI(path: string, condition?: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get((condition === 'domestic' ? this.domesticAPIUrl : this.businessAPIUrl) + path,
      httpOptions
    ).pipe(retry(2), timeout(30000));
  }

  // PATCH API
  patchAPI(path: string, body: any, condition?: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.patch((condition === 'domestic' ? this.domesticAPIUrl : this.businessAPIUrl) + path,
      body,
      httpOptions
    ).pipe(retry(2), timeout(30000));
  }

  /**
   * 
   * @param {string} path URL for the API
   * @param {string} condition Condition to use domestic API or business API
   * @returns {Observable}
   */
  getAPIForPdf(path: string, condition?: string): Observable<any> {
    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob',
    };
    return this.http
      .get(
        (condition === 'domestic' ? this.domesticAPIUrl : this.businessAPIUrl) +
          path,
        httpOptions
      )
      .pipe(retry(2), timeout(30000));
  }

  // Generic user activity
  userActivity(payload: any): void {
    if (!!this.sessionService.getItemFromSession(Constants.sessionConstants.CORRELATIONID)) {
      payload['correlationId'] = this.sessionService.getItemFromSession(Constants.sessionConstants.CORRELATIONID);
    }
    this.postAPI('carbon/v1/add-user-data', payload, 'domestic').subscribe({
      next: (data) => {
        // Store into session
        this.sessionService.setItemIntoSession(Constants.sessionConstants.CORRELATIONID, data?.correlationId);
      },
      error: (err) => console.log('ERROR: ', err)
    })
  }

  // Generic user activity after login
  addCheckpoint(event: string): void {
    const payload = {
      email: this.sessionService.getItemFromSession(Constants.sessionConstants.EMAIL),
      event: event,
    };

    this.postAPI('carbon/v1/add-checkpoint', payload, 'domestic').subscribe({
      next: (data) => { },
      error: (err) => console.log('ERROR: ', err)
    });
  }

  // Generic recall recommendation API after updated
  getRecommendation(siteDetails: any): void {
    this.spinner.show();
    const payload: any = {
      email: this.sessionService.getItemFromSession(Constants.sessionConstants.EMAIL),
      mpan: siteDetails?.mpan,
      mprn: siteDetails?.mprn,
      lmkKey: siteDetails?.lmkKey
    };

    this.postAPI('carbon/v1/recommendations', payload, 'domestic').subscribe({
      next: (res) => {
        const data = res?.data;
        const recommendations: any[] = Object.keys(data);

        const recommendationStatus = this.sessionService.getItemFromSession(Constants.sessionConstants.RECOMMENDATIONSTATUS);

        // Reset Recommendations
        this.sessionService.removeItemFromSession(Constants.sessionConstants.LIGHTING);
        this.sessionService.removeItemFromSession(Constants.sessionConstants.INSULATION);
        this.sessionService.removeItemFromSession(Constants.sessionConstants.HEATPUMP);
        this.sessionService.removeItemFromSession(Constants.sessionConstants.SOLARPANEL);
        this.sessionService.removeItemFromSession(Constants.sessionConstants.BOILER);
        this.sessionService.removeItemFromSession(Constants.sessionConstants.VO);

        let potentialSavings = 0;
        let totalCost = 0;
        let dashboardData = this.sessionService.getItemFromSession(Constants.sessionConstants.DASHBOARD);
        recommendations.map(record => {
          const recommendation: Recommendation = (record === Constants.recommendation.VOLTAGEOPTIMISATION) ? data[record]?.default : data[record];
          if (recommendation?.potentialSavings && recommendation?.potentialSavings > 0) {
            potentialSavings += recommendation?.potentialSavings;
            totalCost += recommendation?.totalCost;

            dashboardData.potentialSavings = potentialSavings;
            // Stored into session
            this.sessionService.setItemIntoSession(Constants.sessionConstants.DASHBOARD, dashboardData);
            this.sessionService.setItemIntoSession(Constants.sessionConstants.POTENTIALSAVINGS, potentialSavings);
            this.sessionService.setItemIntoSession(Constants.sessionConstants.TOTALCOST, totalCost);
          }

          // Additional fields
          if (record === Constants.recommendation.LIGHTING) {
            recommendation['title'] = 'Energy efficient lighting';
            recommendation['category'] = 'lighting';
            recommendation['status'] = recommendationStatus?.lighting;
            recommendation['description'] = `By upgrading to high-quality LED lighting, you can enjoy longer-lasting and more durable lighting 
              with comparable or superior light quality to other types of lighting. Our Model has analyzed your electricity consumption and 
              building area based on your business type to determine the best lighting solution for you. Make the switch to LED lighting for a 
              more energy-efficient and cost-effective lighting solution.`;

            // Stored into session
            this.sessionService.setItemIntoSession(Constants.sessionConstants.LIGHTING, recommendation);
          } else if (record === Constants.recommendation.INSULATION) {
            recommendation['title'] = 'Insulation';
            recommendation['category'] = 'insulation';
            recommendation['status'] = recommendationStatus?.insulation;
            recommendation['description'] = `Investing in insulation can greatly improve the energy efficiency of your business while reducing 
            your heating and cooling costs. Proper insulation helps to prevent heat loss in the winter and heat gain in the summer, creating a 
            more comfortable indoor environment for your employees and customers. Our Model has analyzed your building's energy consumption and 
            insulation needs based on your building area, heat loss factors and year of build to determine the best insulation solution for your 
            business. By upgrading your insulation, you can enjoy reduced energy bills, improved indoor comfort, and a more eco-friendly 
            operation.`;

            // Stored into session
            this.sessionService.setItemIntoSession(Constants.sessionConstants.INSULATION, recommendation);
          } else if (record === Constants.recommendation.HEATPUMP) {
            recommendation['title'] = 'Heat pump';
            recommendation['category'] = 'heatpump';
            recommendation['status'] = recommendationStatus?.heatpump;
            recommendation['description'] = `Upgrading to a heat pump system is a smart investment that can greatly improve the energy efficiency 
                of your business while reducing your carbon footprint. Heat pumps use a renewable and sustainable energy source to provide both 
                heating and cooling, making them a versatile and cost-effective solution. Our Model has analyzed your building's energy consumption 
                and heating needs to determine the best heat pump system for your business. By making the switch to a heat pump, you can enjoy 
                reduced energy costs, improved indoor air quality, and a more eco-friendly operation.`;

            // Store into session
            this.sessionService.setItemIntoSession(Constants.sessionConstants.HEATPUMP, recommendation);
          } else if (record === Constants.recommendation.SOLARPANEL || record === 'solar') {
            recommendation['title'] = 'Solar panels';
            recommendation['category'] = 'solarpanels';
            recommendation['status'] = recommendationStatus?.solarpanels;
            recommendation['description'] = `Upgrading to solar panels is a smart investment that not only improves the energy efficiency of 
                your business but also reduces your carbon footprint. By generating electricity from sunlight, solar panels offer a sustainable and 
                renewable energy source that can lead to long-term savings on energy bills. Our model has conducted a thorough analysis of your 
                business, including your energy consumption, building area, roof size, and current energy tariff, to provide an accurate estimate of 
                the installation cost and potential savings from solar panels. By making the switch to clean energy, you can enjoy reduced energy 
                costs and a more eco-friendly operation, all while contributing to a more sustainable future.`;

            // Store into session
            this.sessionService.setItemIntoSession(Constants.sessionConstants.SOLARPANEL, recommendation);
          } else if (record === Constants.recommendation.BOILER) {
            recommendation['title'] = 'Boiler';
            recommendation['category'] = 'boiler';
            recommendation['status'] = recommendationStatus?.boiler;
            recommendation['description'] = `Our model accurately calculates the total energy required for heating your building, taking into account 
                the primary fuel and building type. We then use this information to recommend the appropriate size and type of boiler for your 
                property, while also considering the operational costs involved. To customise this according to your specific requirements, please 
                register your interest.`;

            // Store into session
            this.sessionService.setItemIntoSession(Constants.sessionConstants.BOILER, recommendation);
          } else if (record === Constants.recommendation.VOLTAGEOPTIMISATION) {
            recommendation['title'] = 'Voltage optimisation';
            recommendation['category'] = 'voltageOptimisation';
            recommendation['status'] = recommendationStatus?.voltageOptimisation;
            recommendation['description'] = `Voltage optimisation is a proven energy-saving technology that can help reduce your electricity 
                consumption and lower your energy bills. By optimising the voltage supplied to your electrical equipment, you can improve the 
                efficiency of your electrical systems and reduce energy wastage. Our model has analysed your electricity consumption and building 
                area to determine the potential savings from voltage optimisation. By implementing this technology, you can enjoy reduced energy 
                costs and a more sustainable operation.`;
            recommendation['upgrades'] = data[record]?.upgrades;

            // Store into session
            this.sessionService.setItemIntoSession(Constants.sessionConstants.VO, recommendation);
          }
        });
        this.util.setSubjectChange(1);
        this.spinner.hide();
      }, error: (err) => {
        console.log('ERROR: ', err);
        this.spinner.hide();
      }
    })
  }
}