import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import Constants from 'src/app/core/constants/constants';
import { IAddress, ICompany, ICompanyDetails, ICompanyType } from 'src/app/core/models/interface';
import { RestAPIsService } from 'src/app/core/services/rest-apis.service';
import { SessionService } from 'src/app/core/services/session.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
  @Input() modalRef: any
  addresses$!: Observable<[]>;
  listOfCompanies: Array<ICompany> = [];
  companyForm: FormGroup;
  postcodeRegex: RegExp =
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/gm;
  isCompanyLoading: boolean = false;
  isCompanyAddressLoading: boolean = false;
  selectedCompanyObj: ICompanyDetails | null = null;
  isCompanyAddress: boolean = false;
  companyAddress: string = '';
  isManualCompany: boolean = false;
  isCompanyNoAddress: boolean = false;
  invalidAddress: string = '';
  isAddressLoading: boolean = false;
  listOfAddress: Array<IAddress> = [];
  isCompanyAddressNotInList: any;
  selectedAddressObj: any;
  isSelectedAddress: boolean = false;
  errorMessage: string = '';
  isSubmitted: boolean = false;
  successMessage: string = '';
  companyTypes: ICompanyType[] = Constants.CompanyType

  constructor(private restAPI: RestAPIsService, private modalService: NgbModal,
    private sessionService: SessionService, private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
    this.companyForm = new FormGroup({
      companyName: new FormControl(null, [Validators.required]),
      companyNameManual: new FormControl(null),
      companyAddress: new FormControl(null, [Validators.required]),
      companyType: new FormControl(null, [Validators.required]),
      companyNumber: new FormControl(null, [Validators.required]),
      address: new FormGroup({
        locality: new FormControl(null),
        address_line_1: new FormControl(null),
        address_line_2: new FormControl(null),
        country: new FormControl(null),
        postal_code: new FormControl(null, [Validators.pattern(this.postcodeRegex)]),
      }),
      selectedGBGAddressId: new FormControl(''),
      isCheck: new FormControl(false),
    })
  }

  ngOnInit(): void {
    
  }

  getFormControl(formControlName: string) {
    if (!formControlName) return null
    return this.companyForm.get(formControlName)
  }

  getErrorControl(formControlName: string) {
    if (!formControlName) return null
    return this.companyForm.get(formControlName)?.errors
  }

  /**
   * @description Search input address from API
   * @param {Object} e Example: `{term: "input"}` 
   */
  searchFreeText(e: any): void {
    if (e.term.length <= 2)
      return;
    this.isAddressLoading = true;
    this.listOfAddress = [];
    // this.counter++;
    // const limiter = this.counter;
    const path = `carbon/v1/free-text-address-search?address=${e.term}`;
    // this.subscriptions.push(
    this.restAPI.getAPI(path, 'domestic').subscribe({
      next: (res) => {
        res?.data?.map((address: any) => {
          address['postcode'] =
            address.Description.split(',')[
              address.Description.split(',').length - 1
            ].trim();
          address['fullAddress'] = address.Text + ', ' + address.Description;
        });

        // if (limiter === this.counter) {
        this.listOfAddress = res.data;
        this.listOfAddress.push({
          Id: 'notInTheList',
          fullAddress: 'Company address not in the list',
          Description: '',
          Highlight: '',
          Text: '',
          Type: ''
        })
        this.isAddressLoading = false;
        // }



        // Check GBG Address already there or not
        if (
          !!this.sessionService.getItemFromSession(
            Constants.sessionConstants.SELECTEDGBGADDRESS
          )
        ) {
          this.companyForm.patchValue({
            selectedGBGAddressId: this.sessionService.getItemFromSession(
              Constants.sessionConstants.SELECTEDGBGADDRESS
            ),
          });

          // Reinitialize the selected address
          this.selectedAddressObj = this.listOfAddress.find(
            (address) =>
              address.Id ===
              this.companyForm.controls['selectedGBGAddressId']?.value
          );
        }

        // Store address text
        this.sessionService.setItemIntoSession(
          Constants.sessionConstants.COMPANYADDRESS,
          this.companyForm.controls['companyAddress']?.value
        );
        // this.spinner.hide();
      },
      error: (err) => {
        console.log('ERROR: ', err);
        // if (limiter === this.counter) {
        this.invalidAddress =
          err?.error?.error[0]?.message ||
          'Something went wrong. Please try again.';
        // }
        // this.spinner.hide();
        this.isAddressLoading = false;
        this.listOfAddress.push({
          Id: 'notInTheList',
          fullAddress: 'Company address not in the list',
          Description: '',
          Highlight: '',
          Text: '',
          Type: ''
        })
      },
    })
    // );
  }

  selectCompany(selectedCompany: any): void {
    console.log(selectedCompany)
    const company = this.companyForm.controls['companyName']?.value
    if (company.number !== 'notInTheList') {
      this.companyForm.patchValue({
        companyName: selectedCompany.name,
        companyNameManual: null,
        companyAddress: null,
        companyType: selectedCompany.type,
        companyNumber: selectedCompany.number
      });
      this.companyForm.get('companyNameManual')?.removeValidators(Validators.required);
      this.companyForm.get('companyNameManual')?.updateValueAndValidity()
      this.selectedCompanyObj = selectedCompany
      this.isManualCompany = false;
      this.getCompanyAddress();
    }
    else {
      // Not in the list
      this.companyForm.patchValue({
        companyName: 'Company not in the list',
        companyAddress: null,
        companyType: null,
        companyNumber: null
      });
      this.companyForm.get('companyNameManual')?.setValidators(Validators.required);
      this.companyForm.get('companyNameManual')?.updateValueAndValidity()
      this.isCompanyNoAddress = false;
      this.selectedCompanyObj = null
      this.isCompanyAddress = false;
      this.isCompanyLoading = false;
      this.isManualCompany = true;
    }
  }

  /**
   * @description Search input company from API
   * @param {Object} e Example: `{term: "input"}` 
   */
  getCompanyDetails(e: any): void {
    const company = e.term;
    const path = `carbon/v1/companies-by-text?searchQuery=${company}`;
    if (company.length > 2) {
      this.listOfCompanies = []
      this.isCompanyLoading = true;
      this.restAPI.getAPI(path, 'domestic').subscribe({
        next: (res) => {
          this.listOfCompanies = res.data;
          this.isCompanyLoading = false;
          this.addNotInList()
          // }
        },
        error: (err) => {
          this.isCompanyLoading = false;
          this.listOfCompanies = [];
        },
      });
    } else {
      this.isCompanyLoading = false;
      this.listOfCompanies = [];
    }
  }

  // Company address information from company details
  getCompanyAddress(): void {
    const searchParam = {
      type: this.selectedCompanyObj?.type || '',
      number: this.selectedCompanyObj?.number || ''
    }
    const path = `carbon/v1/company-details?${new URLSearchParams(searchParam).toString()}`;
    this.isCompanyAddressLoading = true;
    this.restAPI.getAPI(path, 'domestic').subscribe({
      next: (res) => {
        this.companyAddress = res.data[0].addressLine;
        this.isCompanyAddressLoading = false;
        this.isCompanyNoAddress = false;
        if (this.companyAddress) {
          this.isCompanyAddress = true;
          this.companyForm.patchValue({
            companyAddress: this.companyAddress,
          });
        } else {
          this.companyForm.patchValue({
            companyAddress: null,
          });
          this.isCompanyAddress = false;
        }
      },
      error: (err) => {
        console.log('ERROR: ', err);
        // when company not found in the company details list
        this.isManualCompany = true;
        this.isCompanyAddressLoading = false;
        this.isCompanyAddress = false;
        this.isCompanyNoAddress = true;
        this.companyForm.patchValue({
          companyAddress: null,
        });
      },
    });
  }

  // Selected address from dropdown
  selectedGBGAddress(address: any): void {
    if (address.Id !== "notInTheList") {
      this.selectedAddressObj = address;
      this.companyForm.patchValue({
        companyAddress: address.fullAddress,
        selectedGBGAddressId: address.Id,
      });

      this.isSelectedAddress = true;
      this.isCompanyAddressNotInList = false;
      
      this.companyForm.get('address')?.get('locality')?.removeValidators(Validators.required);
      this.companyForm.get('address')?.get('locality')?.updateValueAndValidity()
      this.companyForm.get('address')?.get('address_line_1')?.removeValidators(Validators.required);
      this.companyForm.get('address')?.get('address_line_1')?.updateValueAndValidity()
      this.companyForm.get('address')?.get('country')?.removeValidators(Validators.required);
      this.companyForm.get('address')?.get('country')?.updateValueAndValidity()
      this.companyForm.get('address')?.get('postal_code')?.clearValidators();
      this.companyForm.get('address')?.get('postal_code')?.updateValueAndValidity()
      
    } else {
      this.companyForm.patchValue({
        companyAddress: 'Company address not in the list',
        selectedGBGAddressId: 'notInTheList',
      });

      this.companyForm.get('address')?.get('locality')?.setValidators(Validators.required);
      this.companyForm.get('address')?.get('locality')?.updateValueAndValidity()
      this.companyForm.get('address')?.get('address_line_1')?.setValidators(Validators.required);
      this.companyForm.get('address')?.get('address_line_1')?.updateValueAndValidity()
      this.companyForm.get('address')?.get('country')?.setValidators(Validators.required);
      this.companyForm.get('address')?.get('country')?.updateValueAndValidity()
      this.companyForm.get('address')?.get('postal_code')?.setValidators([Validators.required, Validators.pattern(this.postcodeRegex)]);
      this.companyForm.get('address')?.get('postal_code')?.updateValueAndValidity()

      this.companyForm.get('address')?.markAllAsTouched()

      this.isCompanyAddressNotInList = true;

      // Store into session
      this.sessionService.setItemIntoSession(
        Constants.sessionConstants.COMPANYADDRESS,
        'Not in the list'
      );
      this.sessionService.setItemIntoSession(
        Constants.sessionConstants.SELECTEDGBGPOSTCODE,
        ''
      );
      this.sessionService.setItemIntoSession(
        Constants.sessionConstants.ADDRESSLINE,
        ''
      );
      this.sessionService.setItemIntoSession(
        Constants.sessionConstants.SELECTEDGBGADDRESS,
        'notInTheList'
      );
    }
  }

  addNotInList(): void {
    this.listOfCompanies.push({ number: 'notInTheList', name: 'Company not in the list' })
  }

  // Form data submit for create user and LOA
  onSubmit(): void {
    
    this.errorMessage = '';

    // Check if form is valid and not already submitted
    if (this.companyForm.valid) {
      this.spinner.show()
      const path = 'carbon/v1/add-user-data';
      const userDetails = this.sessionService.getItemFromSession(Constants.sessionConstants.USERDETAILS)
      let payload: any = {
        firstname: userDetails.firstname,
        email: userDetails.email,
        phone: userDetails.phone,
        companyName: this.companyForm.get('companyName')?.value,
        companyAddress: this.companyForm.get('companyAddress')?.value,
        companyType: this.companyForm.get('companyType')?.value,
        companyNumber: this.companyForm.get('companyNumber')?.value
      };

      if (this.companyForm.get('companyName')?.value == 'Company not in the list')
        payload['companyName'] = this.companyForm.get('companyNameManual')?.value;

      if (this.companyForm.get('companyAddress')?.value == 'Company address not in the list') {
        const address = [];
        address.push(
          this.companyForm.get('address.address_line_1')?.value,
          this.companyForm.get('address.address_line_2')?.value,
          this.companyForm.get('address.locality')?.value,
          this.companyForm.get('address.country')?.value,
          this.companyForm.get('address.postal_code')?.value);
        payload['companyAddress'] = address.join(',');
      }

      console.log('Payload prepared for submission:', payload);

      // API call to submit the form data
      // this.subscriptions.push(
      this.restAPI.postAPI(path, payload, 'domestic').subscribe({
        next: (res) => {
          if (res.code === 400 && res.status === 'Error') {
            this.errorMessage = res.data;
            this.isSubmitted = false;
          } else {

            userDetails['companyName'] = payload.companyName
            userDetails['companyAddress'] = payload.companyAddress
            this.sessionService.setItemIntoSession(
              Constants.sessionConstants.USERDETAILS,
              userDetails
            );
            this.spinner.hide()
            this.userService.getLoaConsent(this.modalRef, true)

          }

        },
        error: (err) => {
          this.spinner.hide()
          console.log('ERROR:', err);
          this.isSubmitted = false;
          this.errorMessage =
            err?.error?.error[0]?.message ||
            'Something went wrong. Please try again later.';
        },
      })
      // );
    } else {
      this.companyForm.markAllAsTouched();
    }
  }

  close(): void {
    this.modalService.dismissAll();
  }

}
