<div class="px-lg-5 py-3 py-lg-4 py-xxl-5" *ngIf="!isLoading">
  <span class="fs-20 text-secondary">Login to your</span>
  <h1 class="fs-40 text-championPurple mb-3 mb-lg-4">Account</h1>
  <form [formGroup]="loginForm">
    <div class="mb-4">
      <input type="email" class="form-control inputTyp1" id="email" placeholder="Email" formControlName="email"
        (keydown)="onEnter($event)" />
      <span class="text-danger fs-14" *ngIf="loginForm.get('email')?.errors?.['pattern']">
        Please enter a valid email.
      </span>
      <p class="fs-12 fw400 text-bodyGrey mb-0 mt-2">To log in, please enter your registered email address. We will
        then send you a secure magic link to access your account.</p>
    </div>
    <div class="mb-2 text-center text-danger" *ngIf="!!errorMessage">
      {{errorMessage}}
    </div>
    <div class="mb-2 text-center text-success" *ngIf="!!successMessage">
      {{successMessage}}
    </div>
  </form>

  <div class="btn-group">
    <button class="GA-loginModal btn-primary w-100" type="button" [disabled]="!!isSubmitted" (click)="onSubmit()">
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="!!isSubmitted" aria-hidden="true"></span>
      <span *ngIf="!isSubmitted">Login</span>
    </button>
    <button type="button" class="GA-registerClose btn-primary-alt w-100 w-100 d-md-none" (click)="close()">
      Close
    </button>
  </div>
</div>