import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import Constants from '../core/constants/constants';
import { environment } from 'src/environments/environment';

import { AuthService } from '../core/services/auth.service';
import { SessionService } from '../core/services/session.service';
import { UtilService } from '../core/services/util.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  assetPath: string = '';
  @ViewChild('viewModal') viewModal!: ElementRef;
  pageName = 'register';
  tncUrl = environment.tncUrl;
  source: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private config: NgbModalConfig,
    private util: UtilService,
    private sessionService: SessionService
  ) {
    this.assetPath = this.util.host;
    this.config.centered = true;
  }

  ngOnInit(): void { }

  // Toggle pages
  togglePage(type: any) {
    this.pageName = type;
  }

  // Getting started
  onStart(): void {
    const userDetails = {
      firstname: 'Guest',
      email: '',
      phoneNo: ''
    };

    this.authService.currentUserSubject.next('');

    // Store into session
    this.sessionService.setItemIntoSession(Constants.sessionConstants.USERDETAILS, userDetails);
    this.sessionService.setItemIntoSession(Constants.sessionConstants.EMAIL, userDetails?.email);
    this.sessionService.setItemIntoSession(Constants.sessionConstants.ISNEWJOURNEY, true);
    this.sessionService.setItemIntoSession(Constants.sessionConstants.ISENABLE, true);

    this.router.navigate(['/modules/sites']);
  }

  // Open logged in popup
  openLogin(): void {
    this.config.backdrop = true;
    this.config.keyboard = false;
    this.modalService.open(this.viewModal);
  }
}