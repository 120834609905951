import { Component, OnInit } from '@angular/core';

import { UtilService } from '../core/services/util.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  assetPath: string = '';

  constructor(
    private util: UtilService
  ) { }

  ngOnInit(): void {
    this.assetPath = this.util.host;
  }

}
