import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Buffer } from 'buffer';
import jwt_decode from 'jwt-decode';
import { Amplify, Auth } from 'aws-amplify';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { amplifyconfig } from 'src/environments/authConfig';
import Constants from '../../../core/constants/constants';
import { environment } from 'src/environments/environment';

import { RestAPIsService } from '../../../core/services/rest-apis.service';
import { SessionService } from 'src/app/core/services/session.service';

Amplify.configure(amplifyconfig);
Auth.configure(amplifyconfig);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;
  isUserValid: boolean = true;
  subscriptions: Subscription[] = [];
  loginForm: FormGroup;
  isSubmitted: boolean = false;
  errorMessage: string = '';
  successMessage: string = '';
  userData: any = {};
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private restAPI: RestAPIsService,
    private modalService: NgbModal,
    private sessionService: SessionService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]]
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(async (params) => {
        if (!!params['signature']) {
          // store host
          const info = this.sessionService.getItemFromSession(Constants.CONFIG.INFO);
          // Clear session storage
          this.sessionService.clearSessionStorage();
          // Set the host and source
          this.sessionService.setItemIntoSession(Constants.CONFIG.INFO, info);
          this.isLoading = true;
          try {
            const signature = Buffer.from(
              params['signature'],
              'base64'
            ).toString('binary');
            const decodedSign: any = jwt_decode(signature);

            const { authChallenge, email } = decodedSign;
            const user = await Auth.signIn(email);
            await Auth.sendCustomChallengeAnswer(user, authChallenge);

            // Store into memory
            this.sessionService.setItemIntoSession(Constants.sessionConstants.EMAIL, email);

            const payload = {
              email: email,
            };
            // Get user details
            this.subscriptions.push(
              this.restAPI.postAPI('carbon/v1/user-details?forceRefresh=true', payload, 'domestic').subscribe({
                next: (data) => {
                  const userData = data.data[0];
                  this.userData = userData;

                  // SignIn checkpoint
                  this.restAPI.addCheckpoint('SignIn');

                  // Recommendation status
                  const recommendationStatus = {
                    lighting: userData?.lighting,
                    insulation: userData?.insulation,
                    heatpump: userData?.heatpump,
                    solarpanels: userData?.solarpanels,
                  };

                  // Only first sites
                  const siteData = userData?.sites?.length > 0
                    ? userData?.sites[userData?.sites?.length - 1]
                    : '';

                  if (siteData) {
                    // MasterData
                    const masterData = {
                      elecPostCode: '',
                      gasPostCode: '',
                      elecAddress: '',
                      gasAddress: '',
                      mpan: siteData?.mpan,
                      mprn: siteData?.mprn,
                      isMPANManually: false,
                      isMPRNManually: false,
                      elecConsumption: '',
                      gasConsumption: '',
                      lmkKey: siteData?.lmkKey,
                    };

                    // Store into session
                    this.sessionService.setItemIntoSession(Constants.sessionConstants.MASTERDATA, masterData);
                  }

                  // Store into session
                  this.sessionService.setItemIntoSession(Constants.sessionConstants.USERDETAILS, data.data[0]);
                  this.sessionService.setItemIntoSession(Constants.sessionConstants.RECOMMENDATIONSTATUS, recommendationStatus);
                }, complete: () => {
                  // store flag the user authentication
                  this.sessionService.setItemIntoSession(Constants.sessionConstants.ISVERIFYEMAIL, true);
                  
                  if (this.userData && (!this.userData?.sites || this.userData?.sites?.length == 0)) {
                    this.router.navigate(['modules/sites']);
                  } else {
                    this.router.navigate(['modules/dashboard']);
                  }
                }, error: (err) => {
                  console.log('ERROR: ', err);
                  this.isUserValid = false;
                  this.router.navigate(['/modules/page-not-found'], {
                    queryParams: { content: 'User' },
                  });
                },
              })
            );
          } catch (err) {
            console.log('ERROR: ', err);
            this.router.navigate(['/modules/page-not-found'], {
              queryParams: { content: 'User' },
            });
          }
        }
      })
    );
  }

  // On enter
  onEnter(key: KeyboardEvent): void {
    if (key?.key === 'Enter') {
      this.onSubmit();
    }
  }
  // Close modal
  close(): void {
    this.modalService.dismissAll();
  }

  // Login
  onSubmit(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (!this.isSubmitted && this.loginForm.valid) {
      this.isSubmitted = true;
      const url = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.origin || window.location.origin;
      const source = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.source;
 
      const payload = {
        email: this.loginForm.controls['email'].value,
        subject: (source === 'localhost') ? '' :  ((source === 'consultivutilities') ? 'Consultiv Utilities' : (source === 'advantageutilities') ? 'Advantage Utilities' : source.toUpperCase()) + ' Low Carbon Recommendation Login',
        URL: url + '/login',
      };
      this.subscriptions.push(
        this.restAPI
          .postAPI('carbon/v2/user-login', payload, 'domestic')
          .subscribe({
            next: (res) => {
              this.successMessage = res.message;
              this.isSubmitted = false;
            },
            error: (err) => {
              this.isSubmitted = false;
              if (!!err?.error) {
                this.errorMessage = err.error.error;
              }
            },
          })
      );
    } else {
      this.loginForm.markAllAsTouched();
    }
  }
}