export default class Constants {
  static CONFIG = {
    INFO: 'info'
  }
  static sessionConstants = {
    EMAIL: 'email',
    ADDRESSTEXT: 'addressText',
    COMPANYADDRESS: 'companyAddress',
    SELECTEDGBGPOSTCODE: 'selectedGBGPostcode',
    SELECTEDGBGADDRESS: 'selectedGBGAddress',
    METERLIST: 'meterList',
    METERDETAILS: 'meterDetails',
    GBGADDRESSOBJ: 'gbgAddressObj',
    MASTERDATA: 'masterData',
    USERDETAILS: 'userDetails',
    POTENTIALSAVINGS: 'potentialSavings',
    POTENTIALCARBONSAVINGS: 'potentialCarbonSavings',
    TOTALCOST: 'totalCost',
    RECOMMENDATIONSTATUS: 'recommendationStatus',
    LIGHTING: 'lightingRecommendation',
    DASHBOARD: 'dashboard',
    INSULATION: 'insulationRecommendation',
    HEATPUMP: 'heatpumpRecommendation',
    SOLARPANEL: 'solarpanelsRecommendation',
    BOILER: 'boilerRecommendation',
    VO: 'voltageOptimisationRecommendation',
    VOData: 'voltageOptimisationData',
    SELECTEDSITE: 'selectedSite',
    ALREADYREGISTERED: 'alreadyRegistered',
    ACTIVETAB: 'activeTab',
    RETURNURL: 'returnUrl',
    CONSENT: 'consent',
    ADDRESSLINE: 'addressLine',
    ISNEWJOURNEY: 'isNewJourney',
    ISVERIFYEMAIL: 'isVerifyEmail',
    ISENABLE: 'isEnable',
    CORRELATIONID: 'correlationId',
    ISELIGIBLEMETER: 'isEligibleMeter',
    BEHAVIOURALINSIGHTS: 'behaviouralInsights',
    SOLARPANELDATA: 'solarPanelData',
    REDIRECTIONURL: 'redirectionUrl',
    SHOWLOAPOPUP: 'showLOAPopup'
  };
  static recommendation = {
    LIGHTING: 'lighting',
    INSULATION: 'insulation',
    HEATPUMP: 'heatpump',
    SOLARPANEL: 'solarpanels',
    BOILER: 'boiler',
    VOLTAGEOPTIMISATION: 'voltageOptimisation'
  }
  static NAMEREGEX: RegExp = new RegExp(
    `/^([A-Z]|[a-z]|[\u00c0-\u017F])+([s|-|']([A-Z]|[a-z]|[\u00c0-\u017F])*)*$/`
  );
  static EMAILREGEX: RegExp = new RegExp(
    `/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/`
  );
  static ERROR_MESSAGES = {
    DOMESTIC_METER: 'DOMESTIC_METER',
    SITE_EXIST: 'SITE_EXIST',
  };
  static CompanyType = [
    {
      id: 1,
      name: "Sole Trader",
      display_name: "Sole trader",
      image: "./assets/images/common/businessman.png"
    },
    {
      id: 2,
      name: "Partnership",
      display_name: "Partnership/LLP",
      image: "./assets/images/common/handshake.png"
    },
    {
      id: 3,
      name: "Limited Company",
      display_name: "LTD",
      image: "./assets/images/common/portfolio.png"
    },
    {
      id: 4,
      name: "Charity",
      display_name: "Charity",
      image: "./assets/images/common/charity.png"
    },
    {
      id: 5,
      name: "PLC",
      display_name: "PLC",
      image: "./assets/images/common/city-halll.png"
    },
    {
      id: 6,
      name: "Church",
      display_name: "Community /Church",
      image: "./assets/images/common/church.png"
    }
  ]
}