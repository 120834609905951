<div class="wrapper" id="page-not-found">
  <div class="container h-100">
    <a class="d-block d-md-none logo pt-2" role="button">
      <img [src]="assetPath + 'logo.svg'" alt="logo" />
    </a>
    <h1 class="GA-logo landingLogo d-none d-md-block">
      <img [src]="assetPath + 'logo.svg'" alt="logo" title="logo" />
    </h1>

    <div class="row h70vh d-flex align-items-center position-relative">
      <div class="col-12 col-md-12 text-center">
        <div class="d-flex flex-column align-items-center gap-4">
          <img [src]="assetPath + 'page-not-found.svg'" alt="" />
        </div>
        <h1 class="fs-48 text-brightPurple fw700">Oops!</h1>
        <p class="fs-25 text-bodyGrey mb-0">{{ content }} not found.</p>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <button type="button" routerLink="/home" class="GA-getStarted btn-primary">
        Back to home
      </button>
    </div>
  </div>
</div>