import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, switchMap } from 'rxjs';

import { environment } from 'src/environments/environment';
import Constants from '../constants/constants';

import { JwtService } from '../services/jwt.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(
    private jwtService: JwtService,
    private sessionService: SessionService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const config = this.sessionService.getItemFromSession(Constants.CONFIG.INFO);
    // White listed urls
    if (req.url.includes('/oauth/authorize') || req.url.includes('https://solar.googleapis.com') || req.url.includes('https://assets.perse.io') || req.url.includes('localhost:4200')) {
      // to prevent caching
      // const cloneReq = req.clone({ setHeaders: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' } });
      // return next.handle(cloneReq);
      return next.handle(req);
    }

    const splitUrl = (req.url).split(environment.businessAPIUrl);
    const prodURL = (req.url).split(environment.prodAPIUrl);

    let clone = req;
    if (splitUrl.length > 1) {
      let header: any = {};
      // Added api key in the header
      if (req.url.includes('/electricity/addresses') ||
        req.url.includes('/gas/addresses') ||
        req.url.includes('/electricity/meter') ||
        req.url.includes('/gas/meter')
      ) {
        header['e_api_key'] = environment.resource_identifier;
        header['modifiedby'] = config['source'];
      }
      // Business
      if (req.method === 'POST' || req.method === 'PATCH' || req.method === 'PUT') {
        let bodyObj = req.body;
        if (req.body instanceof FormData) {
          const param = new HttpParams({ fromString: 'version=' + encodeURIComponent(environment.enc_version) + '&module=business' + '&subModule=BROKERS' });
          clone = req.clone({ body: bodyObj, params: param, setHeaders: header });
        } else {
          bodyObj['module'] = 'business'
          bodyObj['subModule'] = 'BROKERS'
          bodyObj['version'] = environment.enc_version
          clone = req.clone({ body: bodyObj, setHeaders: header });
        }
      } else if (req.method === 'GET' || req.method === 'DELETE') {
        clone = req.clone({
          params: new HttpParams({ fromString: 'version=' + encodeURIComponent(environment.enc_version) + '&module=business' + '&subModule=BROKERS' }),
          setHeaders: header
        });
      }
      return next.handle(clone);
    } else if (prodURL.length > 1) {
      // PROD
      const appendModule = this.jwtService.initiateAuthentication('basic');

      return this.jwtService.authenticateWithServer(appendModule?.uuidVal).pipe(delay(200)).pipe(switchMap((res, i) => {
        if (!!appendModule?.identify) {
          const utmSource = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.source;
          clone = req.clone({
            setHeaders: {
              'Authorization': appendModule?.identify,
              'e_api_key': environment.resource_locator
            },
            params: new HttpParams({ fromString: 'utmSource=' + utmSource })
          });
          return next.handle(clone);
        }
        return next.handle(clone);
      }));
    } else {
      // Domestic
      const appendModule = this.jwtService.initiateAuthentication('basic');

      return this.jwtService.authenticateWithServer(appendModule?.uuidVal).pipe(delay(200)).pipe(switchMap((res, i) => {
        if (!!appendModule?.identify) {
          const utmSource = this.sessionService.getItemFromSession(Constants.CONFIG.INFO)?.source;
          clone = req.clone({
            setHeaders: {
              'Authorization': appendModule?.identify,
              'e_api_key': environment.resource_identifier
            },
            params: new HttpParams({ fromString: 'utmSource=' + utmSource })
          });
          return next.handle(clone);
        }
        return next.handle(clone);
      }));
    }
  }
}